import React, { useState } from "react";
import { Card, CardMedia, Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faComment, faEye } from "@fortawesome/free-solid-svg-icons";

const VideoCard = ({ video }) => {
   const [isPreviewAvailable, setIsPreviewAvailable] = useState(true);
  
    const [hovered, setHovered] = useState(false);
    const handleImageError = () => {
      setIsPreviewAvailable(false);
    };

  return (
    <Card
      sx={{
        width: "100%",
        aspectRatio: "7/8", // Maintain square aspect ratio
        position: "relative",
        cursor: "pointer",
        overflow: "hidden",
       
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => window.open(`/videos/${video.video_id}`, "_blank")} // Open details page in new tab
    >
      {/* Background Image */}
      {isPreviewAvailable && video.preview ? (
      <CardMedia
        component="img"
        image={video.preview || "fallback-image.jpg"}
        alt="Video preview"
        onError={handleImageError}
      
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      ):(
          <Box 
          sx={{
            width: "100%",
            backgroundColor: '#000', 
            height: "100%",
            objectFit: "cover",
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}
          >
            <Typography variant="h6" color="white">Video Unavailable</Typography>
          </Box>
        )}
      

      {/* Overlay for dimming */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: hovered
            ? "rgba(0, 0, 0, 0.5)" // Darker dimming on hover
            : "rgba(0, 0, 0, 0.2)", // Lighter dimming by default
          transition: "background-color 0.3s ease-in-out",
        }}
      />

      {/* Views Icon */}
      {!hovered && (
        <Box
          sx={{
            position: "absolute",
            bottom: "8px",
            left: "8px",
            color: "white",
            borderRadius: "6px",
            fontSize: "0.9rem",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <FontAwesomeIcon icon={faEye} />
          <Typography variant="body2">{video.views_total}</Typography>
        </Box>
      )}

      {/* Likes and Comments Centered */}
      {hovered && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            borderRadius: "4px",
            fontSize: "1rem",
            display: "flex",
            justifyContent: "center",
            gap: "30px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <FontAwesomeIcon icon={faHeart} />
            <Typography variant="body2">{video.likes_total}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <FontAwesomeIcon icon={faComment} />
            <Typography variant="body2">{video.comments_total}</Typography>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default VideoCard;









