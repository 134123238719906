import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FaYoutube, FaInstagram, FaTiktok } from "react-icons/fa";
import SearchIcon from "@mui/icons-material/Search";
import TableRowsIcon from "@mui/icons-material/TableRows";
import GridViewIcon from "@mui/icons-material/GridView";
import RefreshIcon from "@mui/icons-material/Refresh"; // Import for refresh icon
import DoneIcon from "@mui/icons-material/Done"; // For Apply button
import ClearIcon from "@mui/icons-material/Clear"; // For Reset button
import VideoCard from "./VideoCard"; // Placeholder for grid card rendering
import axios from "axios";
import { format } from "date-fns";

const NonSponsoredVideos = () => {
  const [view, setView] = useState("grid");
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(16); // For grid view pagination
  const [searchText, setSearchText] = useState("");

  const [filters, setFilters] = useState({
    sponsor: "",
    account: "",
    writer: "",

    platform: "all",
    sortBy: "timestamp_desc",
  });
  const [platform, setPlatform] = useState("all");
  const [accounts, setAccounts] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [writers, setWriters] = useState([]);

  useEffect(() => {
    fetchData();
    fetchFilterOptions();
  }, [filters,searchText]);

 

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/videos/non-sponsored", {
        params: { ...filters},
      });
  
      let data = response.data;
  
      // Apply platform filtering
      if (platform !== "all") {
        data = data.filter((video) => video.platform === platform);
      }
  
      // Apply filtering based on the search text
      const filteredData = data.filter((video) => {
        const searchLower = searchText.toLowerCase();
        return (
          (video.url && video.url.toLowerCase().includes(searchLower)) ||
          (video.title && video.title.toLowerCase().includes(searchLower))
        );
      });
  
      setVideoData(filteredData);
    } catch (error) {
      console.error("Error fetching videos", error);
    }
    setLoading(false);
  };
  
  const handlePlatformChange = (selectedPlatform) => {
    setPlatform(selectedPlatform);
    setFilters((prevFilters) => ({
      ...prevFilters,
      platform: selectedPlatform,
    }));
    setShowFilters(true); // Show filter section when platform is selected
  };
  

  const dataGridStyles = {
    "--DataGrid-overlayHeight": "300px",
    overflow: "clip",
    borderColor: "#E5E7EB",
    backgroundColor: "#FFFFFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: "0.9rem",

    // Column Headers
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#F3F4F6", // Light gray background for a modern look
      color: "#111827", // Dark text for better contrast
      fontSize: "1rem", // Slightly larger font size for clarity
      fontWeight: "600", // Semi-bold for emphasis
      textTransform: "none", // Keep headers clean (no capitalization)
      borderBottom: "2px solid #E5E7EB", // Subtle bottom border
      textAlign: "left", // Align text to the left for readability
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "700", // Stronger emphasis on header titles
        fontSize: "0.95rem", // Slightly refined size for clarity
      },
      "&:hover": {
        backgroundColor: "#E5E7EB", // Subtle hover effect on headers
      },
    },

    // Footer Container
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "#F9FAFB",
      borderTop: "1px solid #E5E7EB",
      fontSize: "0.85rem",
      fontWeight: "500",
    },

    // Checkbox Customizations
    "& .MuiCheckbox-root": {
      padding: "4px",
      "& > svg": {
        fontSize: "1rem",
      },
    },

    // Pagination Controls
    "& .MuiTablePagination-root": {
      marginRight: "8px",
      "& .MuiIconButton-root": {
        maxHeight: "32px",
        maxWidth: "32px",
        "& > svg": {
          fontSize: "1rem",
        },
      },
    },

    // Cell Styling
    "& .MuiDataGrid-cell": {
      borderTop: "1px solid #E5E7EB",
      color: "#374151", // Neutral dark gray for cells
      fontSize: "0.9rem",
      "&:hover": {
        backgroundColor: "#F3F4F6", // Subtle hover effect for cells
      },
    },

    // Row Styling
    "& .MuiDataGrid-row": {
      "&:last-of-type": {
        borderBottom: "1px solid #E5E7EB",
      },
      "&:hover": {
        backgroundColor: "#F9FAFB", // Light hover effect for rows
      },
      "&.Mui-selected": {
        backgroundColor: "#E0E7FF", // Light blue for selected rows
        "&:hover": {
          backgroundColor: "#C7D2FE", // Slightly darker blue on hover
        },
      },
    },

    // Status Indicators
    "& .status-online": {
      color: "#10B981", // Vibrant green for online status
      fontWeight: "bold",
      backgroundColor: "#DCFCE7",
      borderRadius: "12px",
      padding: "2px 8px",
      fontSize: "0.85rem",
      textAlign: "center",
    },
    "& .status-offline": {
      color: "#9CA3AF", // Subtle gray for offline status
      fontWeight: "bold",
      backgroundColor: "#F3F4F6",
      borderRadius: "12px",
      padding: "2px 8px",
      fontSize: "0.85rem",
      textAlign: "center",
    },
  };

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const fetchFilterOptions = async () => {
    try {
      const sponsorsResponse = await axios.get("/api/videos/sponsors");
      const accountsResponse = await axios.get("/api/videos/accounts");
      const writersResponse = await axios.get("/api/videos/writers");
      setSponsors(sponsorsResponse.data);
      setAccounts(accountsResponse.data);
      setWriters(writersResponse.data);
    } catch (error) {
      console.error("Error fetching filter options", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchFilterOptions();
  }, [filters, platform]);

  const handleViewChange = (event, nextView) => {
    setView(nextView);
  };

  // Sorting logic
  const sortData = (data) => {
    switch (filters.sortBy) {
      case "timestamp_asc":
        return data.sort((a, b) => new Date(a.created) - new Date(b.created));
      case "timestamp_desc":
        return data.sort((a, b) => new Date(b.created) - new Date(a.created));
      case "views_asc":
        return data.sort((a, b) => a.views_total - b.views_total);
      case "views_desc":
        return data.sort((a, b) => b.views_total - a.views_total);
      case "likes_asc":
        return data.sort((a, b) => a.likes_total - b.likes_total);
      case "likes_desc":
        return data.sort((a, b) => b.likes_total - a.likes_total);
      case "comments_asc":
        return data.sort((a, b) => a.comments_total - b.comments_total);
      case "comments_desc":
        return data.sort((a, b) => b.comments_total - a.comments_total);
      default:
        return data;
    }
  };

  

  const handleNewSearch = () => fetchData();

  const handleResetFilters = () => {
    setFilters({
      sponsor: "ALL",
      account: "ALL",
      writer: "ALL",
      minViews: "",
      maxViews: "",
      postedBefore: "",
      postedAfter: "",
      sortBy: "timestamp_desc",
    });
    setPlatform("all");
    setSearchText("");
  };

  const columns = [
    { field: "video_id", headerName: "Video ID", width: 100 },
    { field: "url", headerName: "URL", width: 200 },
    {
      field: "details",
      headerName: "Details",
      width: 150,
      renderCell: (params) => (
        <a href={`/videos/${params.row.video_id}`}>Details</a>
      ),
    },
    { field: "name", headerName: "Sponsor", width: 150 },
    { field: "username", headerName: "Account", width: 150 },
    { field: "writername", headerName: "Writer", width: 150 },
    { field: "views_total", headerName: "Views", width: 100 },
    { field: "likes_total", headerName: "Likes", width: 100 },
    { field: "comments_total", headerName: "Comments", width: 100 },
    {
      field: "created",
      headerName: "Timestamp",
      width: 150,
      renderCell: (params) =>
        format(new Date(params.value), "dd/MM/yyyy, HH:mm"),
    },
  ];

  const sortedData = sortData([...videoData]); // Apply sorting logic here

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = sortedData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  return (
    <Box sx={{ p: 3, width: "100%" }}>
      {/* Title and Search Bar */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Non Sponsored Videos
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            size="small"
            label="Search by URL"
            value={searchText}
            onChange={handleSearchChange}
            sx={{ width: 300 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
  
      {/* Filters and View Toggle */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view toggle"
          >
            <ToggleButton value="grid" aria-label="grid view">
              <GridViewIcon />
            </ToggleButton>
            <ToggleButton value="table" aria-label="table view">
              <TableRowsIcon />
            </ToggleButton>
         
          </ToggleButtonGroup>
  
          {/* Sort By Dropdown */}
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={filters.sortBy}
              onChange={(e) => handleFilterChange("sortBy", e.target.value)}
            >
              <MenuItem value="timestamp_desc">Newest First</MenuItem>
              <MenuItem value="timestamp_asc">Oldest First</MenuItem>
              <MenuItem value="views_desc">Most Views</MenuItem>
              <MenuItem value="views_asc">Least Views</MenuItem>
              <MenuItem value="likes_desc">Most Likes</MenuItem>
              <MenuItem value="likes_asc">Least Likes</MenuItem>
              <MenuItem value="comments_desc">Most Comments</MenuItem>
              <MenuItem value="comments_asc">Least Comments</MenuItem>
            </Select>
          </FormControl>
        </Box>
  
        {/* Platform Icons */}
        <Box sx={{ display: "flex", gap: 2 }}>
        <IconButton
    onClick={() => {
      handleFilterChange("platform", "youtube");
      setShowFilters(true); // Show filter section
    }}
    sx={{
      color: filters.platform === "youtube" ? "#ff6d00" : "inherit",
    }}
  >
    <FaYoutube size={24} />
  </IconButton>
          <IconButton
            onClick={() => {
              handleFilterChange("platform", "instagram");
              setShowFilters(true); // Show filter section
            }}
            sx={{
              color: filters.platform === "instagram" ? "#ff6d00" : "inherit",
            }}
          >
            <FaInstagram size={24} />
          </IconButton>
          <IconButton
            onClick={() => {
              handleFilterChange("platform", "tiktok");
              setShowFilters(true); // Show filter section
            }}
            sx={{
              color: filters.platform === "tiktok" ? "#ff6d00" : "inherit",
            }}
          >
            <FaTiktok size={24} />
          </IconButton>
          <IconButton
            onClick={() => {
              setFilters({ platform: "all", sortBy: "timestamp_desc" });
              setShowFilters(false); // Hide filter section
            }}
            sx={{
              color: "#ff6d00",
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
  
      {/* Filters Section */}
      {showFilters && (
        <Box
          sx={{
            mb: 3,
            p: 2,
            border: "1px solid #ffcc80",
            borderRadius: 2,
            backgroundColor: "#fff3e0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              alignItems: "center",
            }}
          >
            {/* Sponsor Dropdown */}
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>Sponsor</InputLabel>
              <Select
                value={filters.sponsor}
                onChange={(e) => handleFilterChange("sponsor", e.target.value)}
              >
                <MenuItem value="">All Sponsors</MenuItem>
                {sponsors.map((sponsor) => (
                  <MenuItem key={sponsor.id} value={sponsor.name}>
                    {sponsor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
  
            {/* Writer Dropdown */}
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>Writer</InputLabel>
              <Select
                value={filters.writer}
                onChange={(e) => handleFilterChange("writer", e.target.value)}
              >
                <MenuItem value="">All Writers</MenuItem>
                {writers.map((writer) => (
                  <MenuItem key={writer.id} value={writer.name}>
                    {writer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
  
            {/* Account Dropdown */}
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>Account</InputLabel>
              <Select
                value={filters.account}
                onChange={(e) => handleFilterChange("account", e.target.value)}
              >
                <MenuItem value="">All Accounts</MenuItem>
                {accounts.map((account) => (
                  <MenuItem key={account.id} value={account.username}>
                    {account.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
  
            {/* Date Filters */}
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                label="Posted After"
                type="date"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={filters.postedAfter || ""}
                onChange={(e) =>
                  handleFilterChange("postedAfter", e.target.value)
                }
              />
              <TextField
                label="Posted Before"
                type="date"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={filters.postedBefore || ""}
                onChange={(e) =>
                  handleFilterChange("postedBefore", e.target.value)
                }
              />
            </Box>
            <Button
              variant="contained"
              startIcon={<DoneIcon />}
              onClick={fetchData}
              sx={{ backgroundColor: "#ff6d00", color: "#fff" }}
            >
              Apply
            </Button>
            <Button
              variant="outlined"
              startIcon={<ClearIcon />}
              onClick={handleResetFilters}
            >
              Reset
            </Button>
          </Box>
  
          
        </Box>
      )}
        {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 300,
          }}
        >
          <CircularProgress />
        </Box>
      ) : view === "table" ? (
        <Box sx={{ height: 600 , width: 1130}}>
          <DataGrid
            rows={videoData}
            columns={columns}
            pageSize={8}
            loading={loading}
            getRowId={(row) => row.video_id}
            components={{ Toolbar: GridToolbar }}
            sx={dataGridStyles}
          />
        </Box>
      ) : (
        <Grid container spacing={0.5}>
          {currentRecords.map((video) => (
            <Grid item xs={6} sm={4} md={3} lg={3} key={video.video_id}>
              <VideoCard video={video} />
            </Grid>
          ))}
        </Grid>
      )}: {view === "grid" && (
        <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          mt: 3,
        }}
      >
          <Button
            variant="outlined"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            ◀
          </Button>
          <Typography>
            Page {currentPage} of {Math.ceil(videoData.length / recordsPerPage)}
          </Typography>
          <Button
            variant="outlined"
            onClick={() =>
              setCurrentPage((prev) =>
                Math.min(prev + 1, Math.ceil(videoData.length / recordsPerPage))
              )
            }
            disabled={currentPage === Math.ceil(videoData.length / recordsPerPage)}
          >
            ▶
          </Button>
        </Box>
      )}
    </Box>
  );
  
  
};

export default NonSponsoredVideos;
