import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Container,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
} from '@mui/material';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const extractVideoId = (url) => {
  if (!url) return null;

  try {
    // Create a new URL object
    const parsedUrl = new URL(url);

    // Get the pathname (e.g., /reel/DEERFLZR6h5 or /watch?v=12345)
    const pathname = parsedUrl.pathname;

    // Regex to match the video ID in the pathname
    const videoIdMatch = pathname.match(/(?:\/(?:reel|watch|video|embed|v)\/|[?&]v=)([a-zA-Z0-9_-]{5,})/);

    // If a match is found, return the video ID
    return videoIdMatch ? videoIdMatch[1] : null;
  } catch (error) {
    console.error("Invalid URL format:", error);
    return null;
  }
};


const VideoEntry = () => {
  const [entryType, setEntryType] = useState('sponsored'); // Toggle state
  const [writers, setWriters] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedWriter, setSelectedWriter] = useState('');
  const [selectedSponsor, setSelectedSponsor] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [url, setUrl] = useState('');
  const [scriptTitle, setScriptTitle] = useState(''); // New state for script title
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const writerResponse = await axios.get('/api/writers');
        setWriters(writerResponse.data);
        if (entryType === 'sponsored') {
          const sponsorResponse = await axios.get('/api/sponsors');
          const userResponse = await axios.get('/api/users');
          setSponsors(sponsorResponse.data);
          setUsers(userResponse.data);
        }
      } catch (error) {
        setError('Error fetching data');
      }
    };
    fetchOptions();
  }, [entryType]);

  const handleToggle = (event, newType) => {
    if (newType !== null) {
      setEntryType(newType);
      setError('');
      setSuccess('');
      setUrl('');
      setScriptTitle(''); // Reset script title
      setSelectedWriter('');
      setSelectedSponsor('');
      setSelectedUser('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    const videoId = extractVideoId(url);
    if (!videoId) {
      setError('Invalid URL. Please provide a valid video URL.');
      return;
    }
  
  
    

    try {

      const validateResponse = await axios.post('/api/videos/validate', {
        video_id: videoId,
      });

      if (!validateResponse.data.isValid) {
        setError('This video has already been submitted.');
        return;
      }

      const data = {
        writer_name: selectedWriter,
        url,
        script_title: scriptTitle, // Include script title
      };

      if (entryType === 'sponsored') {
        data.sponsor_name = selectedSponsor;
        data.user_name = selectedUser;
        data.video_type = 'Sponsored';
      }

      const response = await axios.post(
        entryType === 'sponsored' ? '/api/videos' : '/api/nonsponsored',
        data
      );

      if (response.data.success) {
        setSuccess(`${entryType === 'sponsored' ? 'Sponsored' : 'Non-Sponsored'} video entry added successfully!`);
        setUrl('');
        setScriptTitle(''); // Reset script title
      } else {
        setError('Error submitting the video entry');
      }
    } catch (error) {
      setError('Error submitting the video entry. Please try again later.');
    }
  };

  const handleCsvOrExcelUpload = async (e) => {
    setError('');
    setSuccess('');
    const file = e.target.files[0];

    if (!file) return;

    const fileExtension = file.name.split('.').pop();
    if (fileExtension === 'csv') {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          await processFileData(results.data);
        },
      });
    } else if (fileExtension === 'xlsx') {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        await processFileData(jsonData);
      };
      reader.readAsBinaryString(file);
    } else {
      setError('Unsupported file type. Please upload a CSV or Excel file.');
    }
  };

  const processFileData = async (data) => {
    try {
      const response = await axios.post('/api/videos/bulk', { data, video_type: entryType });
      if (response.data.success) {
        setSuccess('File data processed and video entries added successfully!');
      } else {
        setError('Error processing file data');
      }
    } catch (error) {
      setError('Error uploading the file data');
    }
  };

  return (
    <Container width="100" sx={{ mt: 4,marginLeft:'300px',width:'600px' }}>
      <Card elevation={3} sx={{ borderRadius: 3, overflow: 'hidden' }}>
        <CardContent>
          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>
              Video Entry
            </Typography>
          </Box>

          <ToggleButtonGroup
            value={entryType}
            exclusive
            onChange={handleToggle}
            sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}
          >
            <ToggleButton value="sponsored" sx={{ px: 3 }}>
              Sponsored
            </ToggleButton>
            <ToggleButton value="non-sponsored" sx={{ px: 3 }}>
              Non-Sponsored
            </ToggleButton>
          </ToggleButtonGroup>

          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {entryType === 'sponsored' && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Sponsor Name</InputLabel>
                    <Select
                      value={selectedSponsor}
                      onChange={(e) => setSelectedSponsor(e.target.value)}
                      label="Sponsor Name"
                      required
                    >
                      <MenuItem value="">
                        <em>Select Sponsor</em>
                      </MenuItem>
                      {sponsors.map((sponsor) => (
                        <MenuItem key={sponsor.id} value={sponsor.name}>
                          {sponsor.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {entryType === 'sponsored' && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>User Name</InputLabel>
                    <Select
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                      label="User Name"
                      required
                    >
                      <MenuItem value="">
                        <em>Select User</em>
                      </MenuItem>
                      {users.map((user) => (
                        <MenuItem key={user.id} value={user.username}>
                          {user.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Writer Name</InputLabel>
                  <Select
                    value={selectedWriter}
                    onChange={(e) => setSelectedWriter(e.target.value)}
                    label="Writer Name"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Writer</em>
                    </MenuItem>
                    {writers.map((writer) => (
                      <MenuItem key={writer.id} value={writer.name}>
                        {writer.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {entryType === 'non-sponsored' && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Script Title"
                    value={scriptTitle}
                    onChange={(e) => setScriptTitle(e.target.value)}
                    required
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  type="url"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: '#ff6d00',
                    '&:hover': { backgroundColor: '#e65a00' },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>

          {entryType === 'sponsored' && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">Upload CSV or Excel for Sponsored Videos</Typography>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{
                  mt: 1,
                  backgroundColor: '#ff6d00',
                  '&:hover': { backgroundColor: '#e65a00' },
                }}
              >
                Upload File
                <input
                  type="file"
                  accept=".csv,.xlsx"
                  hidden
                  onChange={handleCsvOrExcelUpload}
                />
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default VideoEntry;
