import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
  TextField,
  Grid,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CategoryIcon from "@mui/icons-material/Category";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";

import AdminSidebar from "./AdminSidebar";

const Settings = () => {
  const [apiKey, setApiKey] = useState("");
  const [token, setToken] = useState("");
  const [listId, setListId] = useState("");
  const [editing, setEditing] = useState(false);
  const [currentSection, setCurrentSection] = useState("Settings");
  const [writers, setWriters] = useState([]);

  const [editingAccount, setEditingAccount] = useState(null);

  const [accountList, setAccountList] = useState([]);
  const [newAccount, setNewAccount] = useState({
    account: "",
    platform: "",
    status: "",
  });
  const [editingRow, setEditingRow] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);

  const platforms = ["TikTok", "YouTube", "Instagram", "Facebook"];
  const statuses = ["Active", "Inactive", "Exclusive"];
  const [tropes, setTropes] = useState([]);
  const [newTrope, setNewTrope] = useState({ number: "", name: "" });
  const [isEditingRow, setIsEditingRow] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [currentTab, setCurrentTab] = useState("accounts");

  useEffect(() => {
    const fetchWriters = async () => {
      try {
        const response = await axios.get("/api/writers");
        setWriters(response.data); // Store the writers
      } catch (error) {
        console.error("Error fetching writers:", error);
      }
    };

    fetchWriters(); // Fetch writers
  }, []);

  useEffect(() => {
    const fetchTropes = async () => {
      try {
        const response = await axios.get("/api/tropes");
        setTropes(response.data);
      } catch (error) {
        console.error("Error fetching tropes:", error);
      }
    };

    fetchTropes();
  }, []);

  // Fetch settings and account list on component mount
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get("/api/settings");
        const { api_key, token, list_id } = response.data;
        setApiKey(api_key);
        setToken(token);
        setListId(list_id);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    const fetchAccounts = async () => {
      try {
        const response = await axios.get("/api/accounts");
        setAccountList(
          response.data.accounts.map((acc) => ({
            ...acc,
            id: acc.posting_account_id,
          }))
        );
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    fetchSettings();
    fetchAccounts();
  }, []);

  // Handle save of new settings
  const handleSave = async (newApiKey, newToken, newListId) => {
    try {
      await axios.post("/api/settings", {
        apiKey: newApiKey,
        token: newToken,
        listId: newListId,
      });
      setApiKey(newApiKey);
      setToken(newToken);
      setListId(newListId);
      setEditing(false);
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };

  // ✅ Add new account
  const handleAddAccount = async () => {
    if (!newAccount.account || !newAccount.platform || !newAccount.status)
      return;

    try {
      const writerIds = newAccount.writers?.map((writer) => writer.id) || [];
      const response = await axios.post("/api/accounts", {
        ...newAccount,
        writer_ids: writerIds,
      });

      setAccountList((prev) => [
        ...prev,
        { ...response.data, id: response.data.id },
      ]);
      setNewAccount({ account: "", platform: "", status: "", writers: [] });
    } catch (error) {
      console.error("Error adding account:", error);
    }
  };

  // ✅ Edit account
  const handleEditAccount = async (id) => {
    if (!editingAccount) return;

    try {
      const writerIds = editingAccount.writers.map((writer) => writer.id);
      const response = await axios.put(`/api/accounts/${id}`, {
        ...editingAccount,
        writer_ids: writerIds,
      });

      setAccountList((prev) =>
        prev.map((acc) =>
          acc.id === id
            ? { ...acc, ...response.data, writers: editingAccount.writers }
            : acc
        )
      );
      setEditingRow(null);
    } catch (error) {
      console.error("Error updating account:", error);
    }
  };

  // ✅ Open delete confirmation dialog
  const handleDeleteConfirmation = (id) => {
    setAccountToDelete(id);
    setDeleteConfirm(true);
  };

  // ✅ Delete account
  const handleDeleteAccount = async () => {
    try {
      await axios.delete(`/api/accounts/${accountToDelete}`);
      setAccountList((prev) =>
        prev.filter((acc) => acc.id !== accountToDelete)
      );
      setDeleteConfirm(false);
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const handleAddTrope = () => {
    if (!newTrope.number || !newTrope.name) return;

    axios
      .post("/api/tropes", newTrope)
      .then((response) => {
        setTropes((prev) => [...prev, response.data]);
        setNewTrope({ number: "", name: "" }); // Reset input fields
      })
      .catch((error) => {
        console.error("Error adding trope:", error);
      });
  };

  const handleEditClick = (row) => {
    setIsEditingRow(row.id);
    setEditingValue(row.name);
  };

  const handleSaveEdit = async (id) => {
    try {
      await axios.put(`/api/tropes/${id}`, { name: editingValue });
      setTropes((prev) =>
        prev.map((trope) =>
          trope.number === id ? { ...trope, name: editingValue } : trope
        )
      );
      setIsEditingRow(null);
      setEditingValue("");
    } catch (error) {
      console.error("Error saving trope:", error);
    }
  };

  const handleDeleteTrope = async (id) => {
    try {
      await axios.delete(`/api/tropes/${id}`); // Delete the trope from the backend
      setTropes((prev) => prev.filter((t) => t.id !== id)); // Filter out the deleted trope locally
    } catch (error) {
      console.error("Error deleting trope:", error);
    }
  };

  const handleChange = (event, newTab) => {
    if (newTab !== null) {
      setCurrentTab(newTab);
    }
  };

  const columns = [
    {
      field: "account",
      headerName: "Account Name",
      flex: 1,
      renderCell: (params) =>
        editingRow === params.row.id ? (
          <TextField
            fullWidth
            value={editingAccount?.account || params.value}
            onChange={(e) =>
              setEditingAccount((prev) => ({
                ...prev,
                account: e.target.value,
              }))
            }
          />
        ) : (
          <Typography>{params.value}</Typography>
        ),
    },
    {
      field: "platform",
      headerName: "Platform",
      flex: 1,
      renderCell: (params) =>
        editingRow === params.row.id ? (
          <Select
            fullWidth
            value={editingAccount?.platform || params.value}
            onChange={(e) =>
              setEditingAccount((prev) => ({
                ...prev,
                platform: e.target.value,
              }))
            }
          >
            {platforms.map((platform) => (
              <MenuItem key={platform} value={platform}>
                {platform}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography>{params.value}</Typography>
        ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) =>
        editingRow === params.row.id ? (
          <Select
            fullWidth
            value={editingAccount?.status || params.value}
            onChange={(e) =>
              setEditingAccount((prev) => ({ ...prev, status: e.target.value }))
            }
          >
            {statuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography>{params.value}</Typography>
        ),
    },
    {
      field: "writers",
      headerName: "Writers",
      flex: 1,
      renderCell: (params) => {
        const isEditing = editingRow === params.row.id;
        return isEditing ? (
          <Select
            multiple
            value={editingAccount?.writers?.map((writer) => writer.id) || []}
            onChange={(e) => {
              const selectedWriterIds = e.target.value;
              setEditingAccount((prev) => ({
                ...prev,
                writers: selectedWriterIds.map((id) =>
                  writers.find((writer) => writer.id === id)
                ),
              }));
            }}
            fullWidth
          >
            {writers.map((writer) => (
              <MenuItem key={writer.id} value={writer.id}>
                {writer.name}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography>
            {params.value?.map((writer) => writer.name).join(", ") ||
              "No Writers"}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      sortable: false,
      align: "center",
      renderCell: (params) =>
        editingRow === params.row.id ? (
          <>
            <IconButton
              color="primary"
              onClick={() => handleEditAccount(params.row.id)}
            >
              <CheckIcon />
            </IconButton>
            <IconButton color="error" onClick={() => setEditingRow(null)}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              color="primary"
              onClick={() => {
                setEditingRow(params.row.id);
                setEditingAccount(params.row);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => handleDeleteConfirmation(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
    },
  ];

  return (
    <Box sx={{ display: "flex", p: 2 }}>
      {/* Sidebar */}
      <AdminSidebar setCurrentSection={setCurrentSection} />

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, px: 4 }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", mb: 2, color: "#333" }}
        >
          {currentSection}
        </Typography>

        {/* Toggle Button Group */}
        <ToggleButtonGroup
          value={currentTab}
          exclusive
          onChange={handleChange}
          sx={{ mb: 3 }}
        >
         
          <ToggleButton value="accounts">
            <AccountCircleIcon sx={{ mr: 1 }} /> Accounts
          </ToggleButton>
          <ToggleButton value="tropes">
            <CategoryIcon sx={{ mr: 1 }} /> Tropes
          </ToggleButton>
          <ToggleButton value="trello">
            <IntegrationInstructionsIcon sx={{ mr: 1 }} /> Trello
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Manage Tropes Section */}
        {currentTab === "tropes" && (
          <Card variant="outlined" sx={{ p: 2 }}>
            <CardContent>
              <Typography variant="h6">Manage Tropes</Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                  mb: 2,
                  mt: 4,
                }}
              >
                <TextField
                  label="Trope Number"
                  type="number"
                  value={newTrope.number || ""}
                  onChange={(e) =>
                    setNewTrope((prev) => ({ ...prev, number: e.target.value }))
                  }
                />
                <TextField
                  label="Trope Name"
                  value={newTrope.name || ""}
                  onChange={(e) =>
                    setNewTrope((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
                <Button variant="contained" onClick={handleAddTrope}>
                  Add Trope
                </Button>
              </Box>
              <DataGrid
                rows={tropes.map((trope) => ({
                  id: trope.number,
                  ...trope,
                }))}
                sx={{ height: "500px" , border:'none'}}
                columns={[
                  { field: "number", headerName: "Trope Number", flex: 0.3 },
                  {
                    field: "name",
                    headerName: "Trope Name",
                    flex: 1,
                    renderCell: (params) =>
                      isEditingRow === params.row.id ? (
                        <TextField
                          fullWidth
                          value={editingValue || params.row.name}
                          onChange={(e) => setEditingValue(e.target.value)}
                        />
                      ) : (
                        <Typography>{params.value}</Typography>
                      ),
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    
                    sortable: false,
                    renderCell: (params) => (
                      <Box sx={{ display: "flex", gap: 1 }}>
                        {isEditingRow === params.row.id ? (
                          <>
                            <IconButton
                              color="primary"
                              onClick={() => handleSaveEdit(params.row.id)}
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => setIsEditingRow(null)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              color="primary"
                              onClick={() => handleEditClick(params.row)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => handleDeleteTrope(params.row.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    ),
                  },
                ]}
                pageSize={5}
                autoHeight
              />
            </CardContent>
          </Card>
        )}

        <Stack spacing={2} width={"1050px"}>
          {/* Manage Posting Accounts Section */}
          {currentTab === "accounts" && (
            <Card variant="outlined" sx={{ p: 2 }}>
              <CardContent>
                <Typography variant="h6">Manage Posting Accounts</Typography>
                <Divider sx={{ my: 2 }} />
                <Divider sx={{ my: 2 }} />

                {/* Add New Account */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center", // Center elements vertically
                    gap: 2, // Space between items
                    mb: 4, // Margin at the bottom
                  }}
                >
                  <TextField
                    fullWidth
                    label="Account Name"
                    value={newAccount.account || ""}
                    sx={{ flex: 2, maxWidth: "400px" }} // Control width and flex ratio
                    onChange={(e) =>
                      setNewAccount((prev) => ({
                        ...prev,
                        account: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    select
                    label="Platform"
                    value={newAccount.platform || ""}
                    sx={{ flex: 1, minWidth: "120px" }} // Adjust width
                    onChange={(e) =>
                      setNewAccount((prev) => ({
                        ...prev,
                        platform: e.target.value,
                      }))
                    }
                  >
                    {platforms.map((platform) => (
                      <MenuItem key={platform} value={platform}>
                        {platform}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    label="Status"
                    value={newAccount.status || ""}
                    sx={{ flex: 1, minWidth: "120px" }} // Adjust width
                    onChange={(e) =>
                      setNewAccount((prev) => ({
                        ...prev,
                        status: e.target.value,
                      }))
                    }
                  >
                    {statuses.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button
                    variant="contained"
                    onClick={handleAddAccount}
                    sx={{
                      flex: 0,
                      backgroundColor: "#1976d2",
                      color: "#fff",
                      height: "56px", // Match the height of TextFields
                    }}
                  >
                    Add
                  </Button>
                </Box>

                {/* Account List DataGrid */}
                <Box sx={{ height: 400 }}>
                  <DataGrid rows={accountList} columns={columns} autoHeight sx={{border:'none'}} />
                </Box>

                <Dialog
                  open={deleteConfirm}
                  onClose={() => setDeleteConfirm(false)}
                  sx={{
                    "& .MuiPaper-root": {
                      borderRadius: "12px",
                      padding: "20px",
                      minWidth: "320px",
                      textAlign: "center",
                    },
                  }}
                >
                  <DialogTitle
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <WarningAmberIcon
                      sx={{ color: "#FF9800", fontSize: "2rem" }}
                    />{" "}
                    Confirm Deletion
                  </DialogTitle>
                  <DialogContent sx={{ px: 3, pb: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{ color: "#666", fontSize: "0.95rem" }}
                    >
                      Are you sure you want to delete this account? This action{" "}
                      <strong>cannot</strong> be undone.
                    </Typography>
                  </DialogContent>
                  <DialogActions
                    sx={{ justifyContent: "center", gap: 2, pb: 2 }}
                  >
                    <Button
                      onClick={() => setDeleteConfirm(false)}
                      sx={{
                        color: "#1976D2",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        "&:hover": { backgroundColor: "#E3F2FD" },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleDeleteAccount}
                      variant="contained"
                      color="error"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        "&:hover": { backgroundColor: "#D32F2F" },
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
                
              </CardContent>
            </Card>
          )}

          {/* Trello Connection Settings Section */}
          {currentTab === "trello" && (
            <Card variant="outlined" sx={{ p: 2 }}>
              <CardContent>
                <Typography variant="h6">Trello Connection Settings</Typography>
                <Divider sx={{ my: 2 }} />
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="API Key"
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                      disabled={!editing}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Token"
                      value={token}
                      onChange={(e) => setToken(e.target.value)}
                      disabled={!editing}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="List ID"
                      value={listId}
                      onChange={(e) => setListId(e.target.value)}
                      disabled={!editing}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                      {!editing ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setEditing(true)}
                        >
                          Edit
                        </Button>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSave(apiKey, token, listId)}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setEditing(false)}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                
              </CardContent>
            </Card>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default Settings;
